/*
 * @Author: moonsflyer 1782142242@qq.com
 * @Date: 2023-06-06 17:20:35
 * @LastEditors: moonsflyer 1782142242@qq.com
 * @LastEditTime: 2023-06-14 15:21:47
 * @FilePath: \xiangmu1\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as echarts from 'echarts'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import vue3videoPlay from 'vue3-video-play'
import 'vue3-video-play/dist/style.css'
import Gantt from '@xpyjs/gantt';
import '@xpyjs/gantt/dist/index.css';
import VueCookies from 'vue-cookies'
import axios from 'axios'
import pagination from './components/pagination/pagination.vue'
import dayjs from "dayjs"
import VXETable from 'vxe-table'
import dataV from '@jiaminghi/data-view'
import VueLazyLoad from 'vue3-lazy'
import { parseTime } from '@/request/Procurementinterface/time'

const app = createApp(App)
app.provide('$axios', axios)
app.use(VueLazyLoad, {
    loading: require("@/assets/errroew.jpg"), // 图片加载时默认图片
    // error: require("@/assets/1.png") // 图片加载失败时默认图片
    // 还可以有其他配置
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.config.globalProperties.$parseTime = parseTime
app.config.globalProperties.$echarts = echarts
app.config.globalProperties.day = dayjs
app.use(ElementPlus, {
    locale: zhCn,
})
app.use(dataV)
function useTable(app) {
    app.use(VXETable)
}

app.use(useTable)
app.use(router)
app.use(vue3videoPlay)
app.use(Gantt)

app.config.globalProperties.$cookies = VueCookies
app.component('pagination', pagination)
app.mount('#app')
