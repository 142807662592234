<!--
 * @Author: lvjiaqi5656 1665794034@qq.com
 * @Date: 2023-09-15 15:36:09
 * @LastEditors: lvjiaqi5656 1665794034@qq.com
 * @LastEditTime: 2023-09-19 11:27:06
 * @FilePath: \chengshineilao\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  margin-top: 0;
  height: 100%;
}

a {
  text-decoration: none;
}

html,
body {
  margin: 0;
  height: 100%;
}
.centerAndMiddle{
  display:flex;
  justify-content:center;
  align-items:center;
}
.endAndStart{
  display:flex;
  justify-content:flex-end;
  align-items:flex-start;
}
.el-progress.is-success .el-progress-bar__inner {
  background-color: #118EFF !important;
}

.el-progress.is-success .el-progress__text {
  color: #118EFF !important;
}

.uploadDialogStyle .el-dialog__header {
  background-color: #2383fe;
  width: 94%;
  padding: 10px 20px;
}

.uploadDialogStyle .el-dialog__title {
  color: #ffffff;
  font-size: 16px;
}

.uploadDialogStyle .el-dialog__headerbtn {
  top: -5px;
}

.uploadDialogStyle .el-dialog__headerbtn .el-dialog__close {
  color: #ffffff;
}

.cenuitereDialog .el-dialog .el-dialog__header {
  background-color: #3994E1;
  margin-right: 0;
  width: 95.8% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}

.cenuitereDialog .el-dialog__title {
  color: #fff;
}

.cenuitereDialog .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
  top: -8px;
}
.my-dialog-name-a .el-dialog__header {
  background-color: #3994e1 !important;
  padding-top: 0.3rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.my-dialog-name-a .el-dialog__title {
  color: #fff !important;
}

.my-dialog-name-a .el-dialog__headerbtn .el-dialog__close {
  color: #fff !important;
}

.my-dialog-name-a .el-dialog__headerbtn {
  top: -3px !important;
}

.my-dialog-name-a .el-dialog__body {
  padding: 0;
}
</style>
