<!--
 * @Author: lvjiaqi5656 1665794034@qq.com
 * @Date: 2023-08-16 11:35:15
 * @LastEditors: lvjiaqi5656 1665794034@qq.com
 * @LastEditTime: 2023-08-25 10:16:33
 * @FilePath: \chengshineilao\src\components\pagination\pagination.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
        <el-pagination v-model:current-page="currentPage" v-model:page-size="pageSize" :page-sizes="[10,15, 30, 100]"
        layout="total,sizes, prev, pager, next,jumper" :total="total"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" :small="true"/>
</template>
<script>
import {reactive,toRefs,computed,ref} from 'vue'
export default {
    props: {
        pageSize: {
            type: Number
        },
        total: {
            type: Number
        },
        currentPage: {
            type: Number
        },
    },
    emits: ['handleSize','handleCurrent'],
    setup(props, context) {
        const currentPage = computed({
            get() {
                return props.currentPage
            },
            set(val) {
                return val
            }
        })
        const pageSize = computed({
            get() {
                return props.pageSize
            },
            set(val) {
                return val
            }
        })
        const total = computed({
            get() {
                return props.total
            },
            set(val) {
                return val
            }
        })
        const handleSizeChange = (val) =>{
            context.emit('handleSize',val)
        }
        const handleCurrentChange = (val) =>{
            context.emit('handleCurrent',val)
        }
        return{
            currentPage,
            pageSize,
            total,
            handleSizeChange,
            handleCurrentChange
        }
    }
}
</script>
<style scoped>
::v-deep .el-pagination__total{
  color: #fff;
}
::v-deep .el-pagination__jump{
    color: #fff;
}
</style>